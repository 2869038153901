<template>
  <layout-navigation>
    <section :class="$style.page">
      <article :class="$style.grid">
        <div :class="[
          $style.gridItem,
          $style.gridItemLeft,
          $style.gridItemComputers,
        ]"
        >
          <div
            :class="$style.hideButtonContainer"
            @click="toggleComputers()"
          >
            <div
              v-if="isCompListOpened"
              :class="$style.hideText"
            >
              Свернуть список компьютеров
            </div>
            <div
              v-if="!isCompListOpened"
              :class="$style.showText"
            >
              Развернуть список компьютеров
            </div>
            <div
              id="arrow"
              :class="[$style.arrow, { [$style.expanded]: !isCompListOpened }]"
            >
              <img src="@/assets/images/icons/navigation/big-arrow.svg">
            </div>
          </div>
          <div
            id="compTableContainer"
            :class="$style.gridItemComputersTable"
          >
            <div :class="$style.gridItemComputersTableContent">
              <div
                v-if="isCompListOpened"
                :class="$style.gridItemComputersTableContentTable"
              >
                <dragTreeTable
                  :isdraggable="false"
                  :data="treeData"
                />
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.gridItem">
          <div :class="$style.screensContainer">
            <div :class="$style.selectContainer">
              Размер превью
              <vue-select
                v-model="selectedSize"
                label="name"
                :options="options"
                :class="$style.select"
                :reduce="(option) => option.id"
                :clearable="false"
                :searchable="false"
                @input="changeSizeAll"
              />
            </div>
            <div
              v-for="screen in screens"
              :id="screen.id"
              :key="screen.id"
              class="screen"
              :class="$style.singleSreenContainer"
              @click="openFullScreen(screen.id)"
            >
              <div :class="$style.description">
                <div :class="$style.status">
                  {{ screen.computer.nameUser }}
                </div>

                <div
                  v-if="screen.status === 'connected'"
                  :class="[$style.status, $style.green]"
                >
                  Подключено
                </div>
                <div
                  v-if="screen.status === 'disconnected'"
                  :class="[$style.status, $style.red]"
                >
                  Отключено
                </div>
                <div
                  v-if="screen.status === 'error'"
                  :class="[$style.status, $style.red]"
                >
                  Что-то пошло не так
                </div>
                <div
                  v-if="screen.status === 'connecting'"
                  :class="[$style.status, $style.yellow]"
                >
                  Подключение...
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </layout-navigation>
</template>

<script>
import LayoutNavigation from '@/layouts/navigation.vue';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import RFB from '@novnc/novnc';
import jwt from 'jsonwebtoken';
import { getComputersAndVncKey } from '@/api/methods/computers';
import VueSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

export default {
  components: {
    LayoutNavigation,
    dragTreeTable,
    VueSelect,
  },
  data: () => ({
    isCompListOpened: true,
    selectedSize: 1,
    options: [
      { name: 'маленький', id: 1 },
      { name: 'средний', id: 2 },
      { name: 'большой', id: 3 }],
    screens: [],
    key: null,
    host: null,
    port: null,
    targetPort: null,
    password: null,
    computers: [],
    treeData: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: '',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Пользователь',
          field: 'title',
          align: 'left',
          flex: 3,
        },
        {
          title: 'Имя компьютера',
          field: 'user',
          align: 'left',
          flex: 2,
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
  }),
  computed: {
    newComputer() {
      return this.$store.getters['vnc/newComputer'];
    },
  },
  watch: {
    newComputer: {
      handler(computer) {
        if (computer.checked) {
          this.screens.push({ id: computer.id, rfb: null, computer, status: 'connecting' });
          this.$nextTick(() => {
            this.connect(computer);
          });
        } else {
          const indexOfObject = this.screens.findIndex((object) => object.id === computer.id);
          if (indexOfObject > -1) {
            this.screens[indexOfObject].rfb.disconnect();
            this.screens.splice(indexOfObject, 1);
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    toggleComputers() {
      this.isCompListOpened = !this.isCompListOpened;

      const compTableContainer = document.getElementById('compTableContainer');
      const arrow = document.getElementById('arrow');

      if (!this.isCompListOpened) {
        compTableContainer.style.minWidth = '0px';
        arrow.style.marginLeft = '5%';
      } else {
        compTableContainer.style.minWidth = '400px';
        arrow.style.marginLeft = '90%';
      }
    },
    changeSize(element, val) {
      switch (val) {
        case 1:
          element.style.width = '30%';
          break;
        case 2:
          element.style.width = '45%';
          break;
        case 3:
          element.style.width = '100%';
          break;
        default:
          element.style.width = '30%';
          break;
      }
    },
    changeSizeAll(val) {
      const elements = document.getElementsByClassName('screen');
      for (let i = 0; i < elements.length; i++) {
        this.changeSize(elements[i], val);
      }
    },
    setNodeAttributes(node) {
      node.ident = node.id + node.name;
      if (node.type === 'folder') {
        node.opened = true;
        node.open = true;
        node.title = node.name;
        node.isContainChildren = true;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        node.opened = false;
        node.dropDisabled = true;
        node.title = node.nameUser;
        node.user = node.name;
      }
    },
    async fetch() {
      const res = await getComputersAndVncKey();
      this.key = res.key;
      this.host = res.sourceHost;
      this.port = res.sourcePort;
      this.targetPort = res.targetPort;

      res.result.tree.forEach((item) => this.setNodeAttributes(item));
      this.treeData.lists = res.result.tree;
      this.computers = res.result.computers;
    },
    openFullScreen(id) {
      const routeData = this.$router.resolve({
        name: 'fullScreenRemoteViewer',
        params: {
          compId: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    connect(computer) {
      const token = jwt.sign({ host: computer.ipAddress, port: this.targetPort }, this.key);

      let url = `${'ws://'}${this.host}`;

      if (this.port) {
        url += `:${this.port}/${token}`;
      }

      const compElement = document.getElementById(computer.id);

      const rfb = new RFB(compElement, url,
        { credentials: { password: computer.vncPass } });

      this.changeSize(compElement, this.selectedSize);

      rfb.addEventListener('connect', (e) => {
        if (e) {
          const indexOfObject = this.screens.findIndex((object) => object.id === computer.id);
          this.screens[indexOfObject].status = 'connected';
        }
      });

      rfb.addEventListener('disconnect', (e) => {
        if (e) {
          const indexOfObject = this.screens.findIndex((object) => object.id === computer.id);
          if (e.detail.clean) {
            if (this.screens[indexOfObject]) {
              this.screens[indexOfObject].status = 'disconnected';
            }
          } else {
            this.screens[indexOfObject].status = 'error';
          }
        }
      });

      rfb.viewOnly = true;
      rfb.showDotCursor = true;

      const indexOfObject = this.screens.findIndex((object) => object.id === computer.id);
      this.screens[indexOfObject].rfb = rfb;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.page {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.grid {
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  display: flex;
  background: white;
  height: 100%;
  overflow: auto;
  display: flex;
}

.gridItem {
  height: 100%;
  //overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.gridItemLeft {
  flex: 0 20 30%;
}

.gridItemComputers {
  display: flex;

}

.gridItemComputersTable {
  flex: 1 1 100%;
  height: 100%;
  min-width: 400px;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid $borderColor;
  padding-right: 20px;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.screensContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.selectContainer {
  display: flex;
  align-items: center;
  gap: 5%;
  font-size: 14px;
  width: 100%;
  margin: 15px;
}

.singleSreenContainer {
  margin: 7px;
  overflow: hidden;
  cursor: pointer;
}

.status {
  text-align: center;
  padding: 5px;

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }

  &.red {
    background: $red;
  }

}

.description {
  border: 1px solid $borderColor;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.select {
  width: 50%;
}

.arrow {
  cursor: pointer;
  display: inline-flex;
  transform: rotate(90deg);
  min-width: 30px;
  margin: 4px;
  padding: 5px;
  margin-left: 90%;
}

.expanded {
  transform: rotate(-90deg);
}

.hideButtonContainer {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.hideText {
  display: inline-flex;
  margin-bottom: -20px;
  font-size: 13px;
  padding: 0px 10px 0px 42%;
  margin-top: 10px;
}

.showText {
  writing-mode: vertical-lr;
  font-size: 14px;
  position: absolute;
  margin-top: 40px;
}
</style>

<style>
.drag-tree-table {
  margin: 0;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.drag-tree-table-header {
  background: #f5f5f5;
  line-height: 20px;
}

canvas {
  width: 100% !important;
  height: fit-content !important;
  margin: 0px !important;
}
</style>
